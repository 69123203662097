import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { setSurveyTargetingToggle } from "../../redux/survey/survey-targeting-toggle.slice";
import { useCreateSurveyAudienceMutation, useUpdateSurveyAudienceMutation } from "../../redux/survey/survey.endpoints";
import { AnalyticEventsEnum } from "../../services/analytics/analytic-event-enum";
import AnalyticsManager from "../../services/analytics/analytics.service";
import { RadioButtonGrid, RadioButtonGridType } from "../radio-button/radio-button";
import Criteria from "./criteria";
import { Attribute } from "./criteria-settings";

interface CriteriaSelectionProps {
  readonly audienceId: string;
  readonly criteria?: Attribute[];
  readonly extSurveyId: string;
  readonly isTargeted: boolean;
}

const CriteriaSelection = ({ audienceId, criteria, extSurveyId, isTargeted }: CriteriaSelectionProps): ReactElement => {
  const [updateSurveyAudience, { isLoading: isUpdateLoading }] = useUpdateSurveyAudienceMutation();
  const [createSurveyAudience, { isLoading: isCreateLoading }] = useCreateSurveyAudienceMutation();
  const dispatch = useDispatch();

  const radioButtons = [
    { label: 'Targeted', value: 'targeted' },
    { label: 'Random', value: 'random' },
  ];

  const resetAudience = () => {
    updateSurveyAudience({ id: audienceId, data: { criteria: {} } });
  };

  const handleTargeted = (value: string) => {
    if (value == "random" && audienceId) {
      resetAudience();
    }

    AnalyticsManager.trackEvent(AnalyticEventsEnum.SelectAudienceType, { type: value });
    dispatch(setSurveyTargetingToggle({ surveyId: extSurveyId, isTargeted: value === 'targeted' }));
  };

  // eslint-disable-next-line @typescript-eslint/space-before-function-paren
  const handleCriteriaChange = async (criteria: any) => {
    if (isUpdateLoading || isCreateLoading) {
      return;
    }

    if (!audienceId) {
      void createSurveyAudience({
        criteria,
        survey: extSurveyId,
      });
    } else {
      updateSurveyAudience({ id: audienceId, data: { criteria } });
    }
  };

  // verify share: false should be included in survey.endopoints patchResults
  return (
    <div className="flex flex-col my-6 gap-6">
      <RadioButtonGrid
        buttons={radioButtons}
        extSurveyId={extSurveyId}
        initialValue={isTargeted ? 'targeted' : 'random'}
        onClick={handleTargeted}
        title='Target audience'
        type={RadioButtonGridType.Targeting}
      />
      {isTargeted && (
        <Criteria
          extSurveyId={extSurveyId}
          initialCriteria={criteria}
          onChange={handleCriteriaChange}
        />
      )}
    </div>
  );
};

export default CriteriaSelection;
