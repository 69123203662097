import {useGetDMAListQuery} from "../redux/questionnaire/questionnaire.endpoints";
import {useMemo} from "react";
import {localAttributes} from "../components/criteria/criteria-settings";

export const useAttributeList = () => {
  const { data, isLoading } = useGetDMAListQuery(undefined, { refetchOnMountOrArgChange: true });

  const attributes = useMemo(() => {
    const baseAttributes = localAttributes;
    if (data) {
      return [
        ...baseAttributes,
        {label: 'DMA',
          value: 'dma',
          options: data},
      ];
    }
    return baseAttributes;
  }, [data]);

  return { isLoading, attributes };
};
