import {localAttributes} from "../../components/criteria/criteria-settings";
import ResultFilter from "../../components/result-filter/result-filter";
import {useMemo, useState} from "react";
import {useGetSurveyAudienceQuery} from "../../redux/survey/survey.endpoints";
import {SurveyAudienceCriteria, SurveyAudienceCriteriaKey} from "../../redux/survey/survey.types";
import ResultsSidebarCard from "./results-sidebar-card.component";
import {convertCriteriaToList} from "./view-survey.helpers";
import {Chip, ChipRemoveEvent} from "primereact/chip";
import { RESULT_CHIP_PASS_THROUGH_OPTIONS } from "./view-survey.constants";
import { toCityCase } from "../../helpers/string.helpers";

interface ResultFilterListProps {
  readonly audienceId: string;
  readonly availableDmas: string[];
  readonly isShare: boolean;
  readonly onFilterChange: (filter: SurveyAudienceCriteria) => void;
}

const ResultFilterList = ({ audienceId, availableDmas, isShare, onFilterChange }: ResultFilterListProps) => {
  const { data: audienceData } = useGetSurveyAudienceQuery({ id: audienceId, isShare }, { skip: !audienceId });
  const [filter, setFilter] = useState<SurveyAudienceCriteria>({});
  const usableFilters = useMemo(() => {
    const targetedKeys: string[] = [];

    if (audienceData) {
      targetedKeys.push(...Object.keys(audienceData.criteria));
    }

    let allAttributes = localAttributes;
    if (availableDmas.length) {
      allAttributes = [
        ...allAttributes,
        {
          label: 'DMA',
          value: 'DMA',
          options: availableDmas.map((dma) => ({ label: toCityCase(dma), value: dma })),
        },
      ];
    }

    return allAttributes.filter((attribute) => !targetedKeys.includes(attribute.value))
      .map((att => {
        return {
          ...att,
          initialSelected: filter[att.label as SurveyAudienceCriteriaKey] ?? [],
        };
      }));
  }, [audienceData, availableDmas, filter]);

  const onSubFilterChange = (key: SurveyAudienceCriteriaKey, value: string[]) => {
    const updatedFilter = {
      ...filter,
      [key]: value,
    };
    setFilter(updatedFilter);
    onFilterChange(updatedFilter);
  };

  const onRemoveFilter = (event: ChipRemoveEvent) => {
    const [key, value] = event.value.split(': ');
    const updatedValues = filter[key as SurveyAudienceCriteriaKey]?.filter((val) => key === 'DMA' ? val !== value.toUpperCase() : val !== value) ?? [];
    onSubFilterChange(key as SurveyAudienceCriteriaKey, updatedValues);
  };

  const renderFilterChip = (label: string) => {
    return (
      <Chip
        key={label}
        label={label.startsWith('DMA:') ? `DMA: ${toCityCase(label.split(': ')[1])}` : label}
        pt={RESULT_CHIP_PASS_THROUGH_OPTIONS}
        removable
        onRemove={onRemoveFilter}
      />
    );
  };

  const renderActiveFilters = () => {
    const activeFilters = convertCriteriaToList(filter);

    if (!activeFilters.length) {
      return null;
    }

    return (
      <div className="p-4 flex flex-row flex-wrap gap-3">
        {activeFilters.map((activeFil) => renderFilterChip(activeFil))}
      </div>
    );
  };

  return (
    <ResultsSidebarCard title="Filter demographics">
      {renderActiveFilters()}
      {usableFilters.map((attribute) => (
        <ResultFilter
          key={attribute.label}
          attribute={attribute}
          initialSelected={attribute.initialSelected}
          onFilterChange={onSubFilterChange}
        />
      ))}
    </ResultsSidebarCard>
  );
};

export default ResultFilterList;
