/* eslint-disable camelcase */
import { invisiblyApi } from '../api/invisibly-api';
import { ApiStatusData, InvisiblyApiResponse } from "../../services/api/api-response.types";
import SurveyService from '../../services/surveys/survey.service';
import { FetchSurveyApiResponse, FetchSurveyData } from '../../services/surveys/survey.types';
import { SURVEY_GRID_PAGE_SIZE } from '../../services/surveys/survey.constants';
import {
  Answer, ModerationCheckResults,
  ModerationResponse,
  Question, QuestionCreationData, QuestionUpdateData,
  Survey,
  SurveyAnswerJSON,
  SurveyAudience,
  SurveyAudienceCriteria,
  SurveyAudienceJSON,
  SurveyAudienceSmall,
  SurveyAudienceSmallJSON,
  SurveyFull,
  SurveyFullJSON,
  SurveyJSON,
  SurveyQuestionJSON, SurveyQuestionType, SurveyStats, SurveyStatsJSON,
  SurveyStatus, SurveySummaryData, SurveySummaryDataJSON,
  toSurveyStatus,
  UploadImage,
} from './survey.types';
import { omitNullValues } from '../../helpers/request.helpers';
import { UpdateAnswerParams } from "../admin/survey/survey.types";
import { appendShare } from "../../services/surveys/surveys.helpers";

const surveyEndpoints = invisiblyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query<FetchSurveyData, { survey_status: string, page: number, page_size: number }>({
      query: (data) => ({
        method: 'get',
        params: {
          sort_by: 'created_at',
          sort_order: 'desc',
          survey_status: data.survey_status,
          page: data.page,
          page_size: SURVEY_GRID_PAGE_SIZE,
        },
        url: 'b2b/survey',
      }),
      transformResponse(response: InvisiblyApiResponse<FetchSurveyApiResponse>) {
        return {
          surveys: response.item.items.map(s => SurveyService.parseListSurvey(s)),
          totalObjects: response.item.total_objects,
          totalPages: response.item.total_pages,
        };
      },
    }),

    // must use the ext_survey_id to get the survey, not the id
    getSurvey: builder.query<SurveyFull, { ext_survey_id: string, share?: boolean }>({
      query: ({ ext_survey_id, share }) => {
        return {
          method: 'get',
          url: appendShare(`b2b/survey/${ext_survey_id}`, share),
        };
      },
      serializeQueryArgs: ({ queryArgs: { ext_survey_id } }) => ({ ext_survey_id }),
      transformResponse(response: { item: SurveyFullJSON }): SurveyFull {
        return SurveyService.parseSurvey(response.item);
      },
    }),

    getSurveyStats: builder.query<SurveyStats, { ext_survey_id: string, share?: boolean }>({
      query: ({ ext_survey_id, share }) => ({
        method: 'get',
        params: { all: true },
        url: appendShare(`b2b/survey/${ext_survey_id}/stats`, share ?? false),
      }),
      serializeQueryArgs: ({ queryArgs: { ext_survey_id } }) => ({ ext_survey_id }),
      transformResponse(response: { item: SurveyStatsJSON }): SurveyStats {
        return SurveyService.parseSurveyStats(response.item);
      },
    }),

    getSurveyAudiences: builder.query<{ audiences: SurveyAudience[] }, null>({
      query: () => ({
        method: 'get',
        url: 'b2b/audience',
      }),
      transformResponse(response: { item: SurveyAudienceJSON[] }) {
        return {
          audiences: response.item.map((a: SurveyAudienceJSON) => SurveyService.parseAudience(a)),
        };
      },
    }),

    //return not structured properly, needs status and item
    getSurveyAudience: builder.query<SurveyAudienceSmall, { id: string, isShare?: boolean }>({
      query: ({ id, isShare }) => ({
        method: 'get',
        url: appendShare(`b2b/audience/${id}`, isShare),
      }),
      serializeQueryArgs: ({ queryArgs: { id } }) => ({ id }),
      transformResponse(response: InvisiblyApiResponse<SurveyAudienceSmallJSON>) {
        return SurveyService.parseAudienceSmall(response.item);
      },
    }),

    getSurveyShareLink: builder.query<string, { ext_survey_id: string }>({
      query: ({ ext_survey_id }) => ({
        method: 'get',
        url: `b2b/survey/${ext_survey_id}/share-link`,
      }),
      transformResponse(response: InvisiblyApiResponse<{ link: string }>) {
        return response.item.link;
      },
    }),

    // At this time, we only can set the title for a survey during a post call (image_url will be set later)
    createSurvey: builder.mutation<Survey, { title: string, human_sample_size: number, ai_sample_size: number }>({
      query: (data) => ({
        method: 'post',
        data: { title: data.title, max_completes: data.human_sample_size, ai_max_completes: data.ai_sample_size },
        url: 'b2b/survey',
      }),
      transformResponse(response: InvisiblyApiResponse<SurveyJSON>) {
        const parsedSurvey = SurveyService.parseListSurvey(response.item);

        return {
          ...parsedSurvey,
          ext_survey_id: response.item.ext_survey_id,
        };
      },
      async onQueryStarted({ title }, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          const ext_survey_id = data.extSurveyId;

          patchResult = dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
              Object.assign(draft, data);
            })
          );
        } catch (error) {
          console.error('Error creating survey:', error);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
    }),

    createSurveyAudience: builder.mutation<SurveyAudience, { criteria: SurveyAudienceCriteria, survey: string }>({
      query: (data) => ({
        method: 'post',
        data: data,
        url: 'b2b/audience',
      }),
      transformResponse(response: InvisiblyApiResponse<SurveyAudienceJSON>) {
        return SurveyService.parseAudience(response.item);
      },
      async onQueryStarted({ survey: ext_survey_id }, { dispatch, queryFulfilled }) {
        let patchResult: any;

        try {
          const { data } = await queryFulfilled;

          patchResult = dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
              const patch = draft;
              patch.audience = data.id;
              Object.assign(draft, patch);
            })
          );
        } catch (error) {
          console.error('Error updating survey:', error);

          if (patchResult) {
            patchResult.undo();
          }
        }
      },
    }),

    createQuestion: builder.mutation<Question, QuestionCreationData>({
      query: (data) => ({
        method: 'post',
        url: 'b2b/survey/question',
        data: { ...data, shuffle: !data.shuffle ? 'none' : 'random' },
      }),
      transformResponse(response: InvisiblyApiResponse<SurveyQuestionJSON>) {
        return {
          ...SurveyService.parseQuestion(response.item),
          id: response.item.id,
        };
      },
      async onQueryStarted({ ext_survey_id }, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;

          patchResult = dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
              draft.questions.push(data);
            })
          );
          await queryFulfilled;
        } catch (error) {
          console.error('Error creating question:', error);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
    }),

    createAnswer: builder.mutation<Answer, { title: string, survey: string, survey_question: string, ext_survey_id: string }>({
      query: (data) => ({
        method: 'post',
        url: 'b2b/survey/question/answer',
        data: data,
      }),
      transformResponse(response: InvisiblyApiResponse<SurveyAnswerJSON>) {
        return { ...SurveyService.parseAnswer(response.item) };
      },
      async onQueryStarted({ ext_survey_id, survey_question }, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const { data } = await queryFulfilled;
          patchResult = dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
              const question = draft.questions.find((q) => q.id === survey_question);
              if (question) {
                const updatedAnswers = [...question.answers, data];
                question.answers = updatedAnswers;
              } else {
                console.error(`Question with id ${survey_question} not found`);
              }
            })
          );
          await queryFulfilled;
        } catch (error) {
          console.error('Error creating answer:', error);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
    }),
    uploadImage: builder.mutation<UploadImage, { formData: FormData, ext_survey_id: string }>({
      query: ({ formData }) => ({
        method: 'POST',
        url: 'b2b/image',
        data: formData,
      }),
      async onQueryStarted({ ext_survey_id }, { dispatch, queryFulfilled }) {
        let patchResult: any;
        try {
          const { data } = await queryFulfilled;
          patchResult = dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
              if (data.item.type === 'survey') {
                draft.imageUrl = data.item.url;
              } else if (data.item.type === 'question') {
                const question = draft.questions.find(q => q.id === data.item.obj_uuid);
                if (question) {
                  question.imageUrl = data.item.url;
                }
              } else if (data.item.type === 'answer') {
                const question = draft.questions.find(q =>
                  q.answers.some(a => a.id === data.item.obj_uuid)
                );
                if (question) {
                  const answer = question.answers.find(a => a.id === data.item.obj_uuid);
                  if (answer) {
                    answer.imageUrl = data.item.url;
                  }
                }
              }
            })
          );

        } catch (error) {
          console.error('Error uploading image:', error);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
    }),

    deleteImage: builder.mutation<void, { image_id: string, ext_survey_id: string, type: string }>({
      query: ({ image_id, type }) => ({
        method: 'DELETE',
        url: `b2b/image/${image_id}?type=${type}`,
      }),
      async onQueryStarted({ ext_survey_id, image_id, type }, { dispatch, queryFulfilled }) {
        let patchResult: any;
        try {
          const { data } = await queryFulfilled;
          patchResult = dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
              if (type === 'survey') {
                draft.imageUrl = null;
              } else if (type === 'question') {
                const question = draft.questions.find(q => q.id === image_id);
                if (question) {
                  question.imageUrl = null;
                }
              } else if (type === 'answer') {
                const question = draft.questions.find(q =>
                  q.answers.some(a => a.id === image_id)
                );
                if (question) {
                  const answer = question.answers.find(a => a.id === image_id);
                  if (answer) {
                    answer.imageUrl = null;
                  }
                }
              }
            })
          );
        } catch (error) {
          console.error('Error deleting image:', error);
          if (patchResult) {
            patchResult.undo();
          }
        }
      },
    }),

    moderationCheck: builder.mutation<ModerationCheckResults, { extSurveyId: string }>({
      query: ({ extSurveyId }) => ({
        url: `b2b/survey/${extSurveyId}/review`,
        method: 'post',
        data: {},
      }),
      transformResponse(response: ModerationResponse) {
        return SurveyService.parseModerationResponse(response);
      },
      async onQueryStarted({ extSurveyId }, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id: extSurveyId }, (draft) => {
              const patch = draft;

              patch.surveyStatus = response.data.didPass ? SurveyStatus.Review : SurveyStatus.Rejected;

              Object.assign(draft, patch);
            }));
        } catch (error) {
          console.error('moderation check error: ', error);
        }
      },
    }),

    updateSurveyAudience: builder.mutation<SurveyAudience, { id: string, data: Partial<SurveyAudienceJSON> }>({
      query: ({ id, data }) => {
        return {
          method: 'put',
          data: data,
          url: `b2b/audience/${id}`,
        };
      },
      transformResponse(response: InvisiblyApiResponse<SurveyAudienceJSON>) {
        return SurveyService.parseAudience(response.item);
      },
      async onQueryStarted({ id, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveyEndpoints.util.updateQueryData('getSurveyAudience', { id }, draft => {
            const patch = draft;

            if (data.criteria) {
              patch.criteria = data.criteria;
            }

            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error updating survey audience:', error);
          patchResult.undo();
        }
      },
    }),

    updateSurvey: builder.mutation<Survey, { title: string; max_completes?: number; image_url?: string; ext_survey_id: string, survey_status?: string, category?: string, ai_max_completes?: number }>({
      query: (data) => ({
        url: `b2b/survey/${data.ext_survey_id}`,
        method: 'PATCH',
        data: omitNullValues({
          title: data.title,
          ai_max_completes: data.ai_max_completes,
          max_completes: data.max_completes,
          image_url: data.image_url,
          survey_status: data.survey_status,
          category: data.category,
        }),
      }),
      transformResponse: (response: InvisiblyApiResponse<SurveyJSON>) => {
        return SurveyService.parseListSurvey(response.item);
      },
      async onQueryStarted({ ext_survey_id, title, max_completes, image_url, survey_status, category, ai_max_completes }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
            const patch = draft;
            patch.title = title;
            if (max_completes !== undefined) {
              draft.maxCompletes = max_completes;
            }
            if (image_url !== undefined) {
              draft.imageUrl = image_url;
            }
            if (survey_status) {
              patch.surveyStatus = toSurveyStatus(survey_status);
            }
            if (category) {
              patch.category = category;
            }
            if (ai_max_completes !== undefined) {
              patch.maxAICompletes = ai_max_completes;
            }
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error updating survey:', error);
          patchResult.undo();
        }
      },
    }),
    updateQuestionOrder: builder.mutation<Survey, { questionArray?: string[], ext_survey_id: string }>({
      query: (data) => ({
        method: 'post',
        data: data.questionArray,
        url: `b2b/survey/${data.ext_survey_id}/question/reorder`,
      }),
      async onQueryStarted({ questionArray, ext_survey_id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
            const patch = draft;
            questionArray?.forEach((questionId, index) => {
              const question = patch.questions.find((q) => q.id === questionId);
              if (question) {
                question.questionOrder = index;
                question.answers.forEach((answer) => {
                  answer.answerBranch = null;
                  answer.isFinalQuestion = false;
                });
              }
            });
            patch.questions.sort((a, b) => a.questionOrder - b.questionOrder);
            Object.assign(draft, patch);
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error updateQuestionOrder:', error);
          patchResult.undo();
        }
      },
    }),

    updateQuestion: builder.mutation<Question, QuestionUpdateData>({
      query: (data) => ({
        method: 'patch',
        data,
        url: `b2b/survey/question/${data.question_id}`,
      }),
      transformResponse(response: InvisiblyApiResponse<SurveyQuestionJSON>) {
        return {
          ...SurveyService.parseQuestion(response.item),
          id: response.item.id,
        };
      },
      async onQueryStarted({ ext_survey_id, title, question_id, image_url, shuffle, type, has_feedback }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, draft => {
            const question = draft.questions.find(q => q.id === question_id);
            if (question) {
              if (type) {
                question.type = type;

                if (type === SurveyQuestionType.MultiSelect) {
                  question.answers.forEach((answer) => {
                    answer.answerBranch = null;
                    answer.isFinalQuestion = false;
                  });
                } else if (type === SurveyQuestionType.Freeform) {
                  question.answers = [];
                }
              }
              question.title = title;
              if (image_url !== undefined) {
                question.imageUrl = image_url;
              }
              if (shuffle !== undefined) {
                question.shuffle = shuffle;
              }
              if (has_feedback !== undefined) {
                question.hasFeedback = has_feedback;
              }
            } else {
              console.error('Question not found:', question_id);
            }
          })
        );

        try {
          const { data } = await queryFulfilled;

          // Update the question in the store to account for changes from freeform to single or multiselect
          dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, draft => {
              const patch = draft;
              const questionIndex = patch.questions.findIndex(q => q.id === question_id);
              if (questionIndex > -1) {
                patch.questions[questionIndex] = data;
              }

              Object.assign(draft, patch);
            })
          );


          // Answers will be added to response, use these to create another patch to update the store

        } catch (error) {
          console.error('Error updating survey:', error);
          patchResult.undo();
        }
      },
    }),

    updateAnswer: builder.mutation<Answer, UpdateAnswerParams>({
      query: (data) => ({
        method: 'patch',
        data: data,
        url: `b2b/survey/question/answer/${data.id}`,
      }),
      async onQueryStarted({ ext_survey_id, title, id, answer_branch, survey_question, is_final_question }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, draft => {
            const patch = draft;
            const answer = patch.questions.find(q => q.id === survey_question)?.answers.find(a => a.id === id);
            if (answer) {
              answer.title = title;
              if (answer_branch !== undefined) {
                answer.answerBranch = answer_branch;
              }
              if (is_final_question !== undefined) {
                answer.isFinalQuestion = is_final_question;
              }
            } else {
              console.error('Question not found:', id);
            }
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error updating updateAnswer:', error);
          patchResult.undo();
        }
      },
    }),

    deleteSurvey: builder.mutation<ApiStatusData, { ext_survey_id: string }>({
      query: (data) => ({
        method: 'DELETE',
        url: `b2b/survey/${data.ext_survey_id}`,
        successCodes: [204],
      }),
      transformResponse(response: InvisiblyApiResponse<void>) {
        return response.status;
      },
      async onQueryStarted({ ext_survey_id }, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
        } catch (error) {
          console.error('Error deleting question:', error);
        }
      },
    }),

    deleteQuestion: builder.mutation<ApiStatusData, { ext_survey_id: string, question_id: string }>({
      query: (data) => ({
        method: 'DELETE',
        url: `b2b/survey/question/${data.question_id}`,
        successCodes: [204],
      }),
      transformResponse(response: InvisiblyApiResponse<void>) {
        return response.status;
      },
      async onQueryStarted({ ext_survey_id, question_id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
            draft.questions = draft.questions.filter(q => q.id !== question_id);
          })
        );
        try {
          const response = await queryFulfilled;
          const survey = await dispatch(surveyEndpoints.endpoints.getSurvey.initiate({ ext_survey_id }));
          const updatedQuestions = survey.data?.questions || [];
          const orderedQuestions = [...updatedQuestions].sort((a, b) => a.questionOrder - b.questionOrder);
          const questionArray = orderedQuestions.map((q) => q.id);


          if (orderedQuestions.length) {
            await dispatch(surveyEndpoints.endpoints.updateQuestionOrder.initiate({
              questionArray,
              ext_survey_id,
            }));
          }
        } catch (error) {
          console.error('Error deleting question:', error);
          patchResult.undo();
        }
      },
    }),

    deleteAnswer: builder.mutation<ApiStatusData, { ext_survey_id: string, question_id: string, answer_id: string }>({
      query: (data) => ({
        method: 'DELETE',
        url: `b2b/survey/question/answer/${data.answer_id}`,
        successCodes: [204],
      }),
      transformResponse(response: InvisiblyApiResponse<void>) {
        return response.status;
      },
      async onQueryStarted({ ext_survey_id, question_id, answer_id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
            const question = draft.questions.find(q => q.id === question_id);
            if (question) {
              question.answers = question.answers.filter(a => a.id !== answer_id);
            }
          })
        );
        try {
          const response = await queryFulfilled;
        } catch (error) {
          console.error('Error deleting answer:', error);
          patchResult.undo();
        }
      },
    }),
    getSurveySummary: builder.query<SurveySummaryData, { ext_survey_id: string, share?: boolean }>({
      query: ({ ext_survey_id, share }) => ({
        method: 'get',
        url: appendShare(`b2b/survey/${ext_survey_id}/summary`, share),
      }),
      serializeQueryArgs: ({ queryArgs: { ext_survey_id } }) => ({ ext_survey_id }),
      transformResponse(response: InvisiblyApiResponse<SurveySummaryDataJSON>) {
        return SurveyService.parseSurveySummary(response.item);
      },
    }),
    shareSurveyResults: builder.mutation<string, string>({
      query: (surveyId) => ({
        method: 'post',
        url: `b2b/survey/${surveyId}/share-results`,
      }),
      transformResponse(response: InvisiblyApiResponse<{ ext_survey_id: string, share_id: string }>) {
        return response?.item?.share_id ?? '';
      },
    }),
  }),
  overrideExisting: true,
});


export const {
  useUploadImageMutation,
  useGetSurveysQuery,
  useGetSurveyStatsQuery,
  useGetSurveyQuery,
  useGetSurveyAudienceQuery,
  useGetSurveyAudiencesQuery,
  useLazyGetSurveyShareLinkQuery,
  useCreateSurveyMutation,
  useCreateSurveyAudienceMutation,
  useUpdateSurveyAudienceMutation,
  useCreateQuestionMutation,
  useCreateAnswerMutation,
  useUpdateSurveyMutation,
  useUpdateQuestionMutation,
  useUpdateQuestionOrderMutation,
  useModerationCheckMutation,
  useUpdateAnswerMutation,
  useDeleteSurveyMutation,
  useDeleteQuestionMutation,
  useDeleteAnswerMutation,
  useDeleteImageMutation,
  useGetSurveySummaryQuery,
  useShareSurveyResultsMutation,
} = surveyEndpoints;

export { surveyEndpoints };
