import {ChipPassThroughOptions} from "primereact/chip";

const RESULT_CHIP_PASS_THROUGH_OPTIONS: ChipPassThroughOptions = {
  label: {
    className: 'text-sm',
  },
  root: {
    className: 'bg-custom-gray',
  },
};
const MAX_ANSWER_COUNT = 8;

export { MAX_ANSWER_COUNT, RESULT_CHIP_PASS_THROUGH_OPTIONS };
