/* eslint-disable @typescript-eslint/ban-ts-comment */
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import RootReducer from './root-reducer';
import {
  createTransform, FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,
} from 'redux-persist';
import { IState, ReduxState } from './redux-state';
import { ThunkDispatch } from 'redux-thunk';
import ReduxService from './redux.service';
import { UnknownAction } from 'redux';
import { getPersistConfig } from 'redux-deep-persist';
import {invisiblyApi} from "./api/invisibly-api";
// import { setupListeners } from '@reduxjs/toolkit/dist/query';


const PersistTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  null,
  // transform state being rehydrated
  (outboundState) => {
    // This transform is to handle migration from 5.12 content offer model to 5.13 content view model and also 5.14 brand offer model to 5.15 survey view model.
    // Outdated cached data has to be invalidated

    return {
      // @ts-ignore
      ...outboundState,
    };
  }
);


const persistConfig = getPersistConfig<IState>({
  blacklist: [
    'apiState',
    'settingState',
    'loginState',
    'userState.signOutInProgress',
  ],
  key: 'root',
  // @ts-expect-error
  rootReducer: RootReducer,
  storage: storage,
  transforms: [PersistTransform],
});

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, RootReducer);

// @ts-ignore
ReduxService.setReduxStore(configureStore<IState>({
  // @ts-ignore
  middleware: (getDefaultMiddleware) => {
    // @see: https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
    const internalMiddleware = getDefaultMiddleware({
      // TODO: Remove serializableCheck: false, immutableCheck: false
      // Temporarily disabling to stop the flood of warnings in console.
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });

    // dante todo
    // return internalMiddleware.concat(invisiblyApi.middleware);
    return internalMiddleware.concat(invisiblyApi.middleware);
  },
  // @ts-ignore
  reducer: persistedReducer,
}));

// setupListeners(ReduxStore.dispatch);
export const Persistor = persistStore(ReduxService.Store);

export type RootState = ReturnType<typeof ReduxService.Store.getState>;
export type AppDispatch = typeof ReduxService.Store.dispatch;
export const ReduxThunkDispatch = ReduxService.Store?.dispatch as ThunkDispatch<ReduxState, unknown, UnknownAction>;
