import {useGetSurveyAudienceQuery} from "../../redux/survey/survey.endpoints";
import {useMemo} from "react";
import ResultsSidebarCard from "./results-sidebar-card.component";
import {Chip} from "primereact/chip";
import {RESULT_CHIP_PASS_THROUGH_OPTIONS} from "./view-survey.constants";
import {toCityCase} from "../../helpers/string.helpers";

export interface ResultsTargetingProps {
  readonly audienceId: string;
  readonly isShare: boolean;
}

const ResultsTargeting = ({ audienceId, isShare }: ResultsTargetingProps) => {
  const { data: audienceData } = useGetSurveyAudienceQuery({ id: audienceId, isShare }, { skip: !audienceId });

  const audiences = useMemo(() => {
    if (!audienceData) {
      return [];
    }

    return Object.entries(audienceData.criteria).flatMap(([key, value]) => {
      return (value as string[]).map(val => `${key}: ${key === 'DMA' ? toCityCase(val) : val}`);
    });
  }, [audienceData]);

  const renderAudience = (audienceString: string) => {
    return (
      <Chip
        key={audienceString}
        label={audienceString}
        pt={RESULT_CHIP_PASS_THROUGH_OPTIONS}
      />
    );
  };

  return (
    <ResultsSidebarCard title={"Targeted audience"}>
      <div className="p-4 flex flex-row flex-wrap gap-3">
        {audiences.map((audience) => renderAudience(audience))}
      </div>
    </ResultsSidebarCard>
  );
};

export default ResultsTargeting;
