import { useState, useEffect, useRef } from 'react';
import plus from '../../assets/icons/plus.svg';
import NestedDropdown from './criteria-components.tsx/nested-dropdown';
import AddedCriteria from './criteria-components.tsx/added-criteria';
import { Attribute } from './criteria-settings';
import { SurveyStatus } from '../../redux/survey/survey.types';
import { useGetSurveyQuery } from '../../redux/survey/survey.endpoints';
import FirebaseService from "../../services/firebase/firebase.service";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';
import { useAttributeList } from '../../hooks/useAttributeList';

const { AddCriteria, ContactSupport } = AnalyticEventsEnum;

const deepEqual = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

interface CriteriaProps {
  extSurveyId: string;
  onChange: (criteria: any) => void;
  initialCriteria?: Attribute[];
}

const Criteria = ({ extSurveyId, onChange, initialCriteria }: CriteriaProps) => {
  const maxCriteria = FirebaseService.getRemoteConfigNumber('MAX_CRITERIA');
  const { attributes } = useAttributeList();
  const [showMultiSelects, setShowMultiSelects] = useState<boolean>(false);
  const [availableCriteria, setAvailableCriteria] = useState<number>(maxCriteria);
  const [createdAttribute, setCreatedAttribute] = useState<Attribute | null>(null);
  const [chosenAttributes, setChosenAttributes] = useState<Attribute[]>(initialCriteria || []);
  const prevCriteriaRef = useRef<Attribute[]>(initialCriteria || []);
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  // Effect to set chosenAttributes only when initialCriteria changes
  useEffect(() => {
    if (initialCriteria && !deepEqual(initialCriteria, chosenAttributes)) {
      setChosenAttributes(initialCriteria);
      prevCriteriaRef.current = initialCriteria;
    }
  }, [initialCriteria]);

  // Effect to handle criteria change
  useEffect(() => {
    const criteria: Record<string, string[]> = {};
    chosenAttributes.forEach(attr => {
      criteria[attr.label] = attr.options.map(opt => opt.value);
    });

    // Only call onChange if criteria has changed
    if (!deepEqual(prevCriteriaRef.current, chosenAttributes)) {
      onChange(criteria);
      prevCriteriaRef.current = chosenAttributes; // Update the ref with the latest criteria
    }
  }, [chosenAttributes]);

  // Effect to update available criteria count
  useEffect(() => {
    setAvailableCriteria(maxCriteria - chosenAttributes.length);
  }, [chosenAttributes]);

  const handleButtonClick = () => {
    !showMultiSelects && AnalyticsManager.trackEvent(AddCriteria);
    setShowMultiSelects(prevState => !prevState);
  };

  const handleMultiSelectChange = (chosenAttribute: Attribute, index: number) => {
    if (chosenAttribute.options.length === 0 && chosenAttribute.label === chosenAttributes[index].label) {
      handleDeleteCriteria(index);
      return;
    }

    setChosenAttributes(prevAttributes => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = chosenAttribute;
      return updatedAttributes;
    });
  };

  const handleDeleteCriteria = (index: number) => {
    setChosenAttributes(prevAttributes => prevAttributes.filter((_, i) => i !== index));
  };

  const handleContactEvent = () => {
    AnalyticsManager.trackEvent(ContactSupport);
  };

  useEffect(() => {
    if (showMultiSelects || createdAttribute === null || (createdAttribute && createdAttribute.options.length === 0)) {
      return;
    }
    setChosenAttributes(prevAttributes => [...prevAttributes, createdAttribute]);
    setCreatedAttribute(null);
  }, [showMultiSelects, createdAttribute]);

  const onCreateAttribute = (newAttribute: Attribute | null) => {
    setCreatedAttribute(newAttribute);
    setShowMultiSelects(false);
  };

  const onDropdownClose = () => {
    setShowMultiSelects(false);
  };

  return (
    <div>
      <div className="font-bold">
        Criteria
      </div>
      {chosenAttributes && chosenAttributes.length > 0 && chosenAttributes.map((attribute, index) => (
        <AddedCriteria
          extSurveyId={extSurveyId}
          key={index}
          updateIndex={index}
          index={attributes.findIndex(attr => attr.label === attribute.label)}
          onDelete={() => handleDeleteCriteria(index)}
          handleMultiSelectChange={handleMultiSelectChange}
          {...attribute}
        />
      ))}
      {availableCriteria === 0 || data?.surveyStatus === SurveyStatus.Review ? (
        <div />
      ) : (
        <button
          type="button"
          onClick={handleButtonClick}
          className={`mt-2 flex w-full items-center rounded-lg border-2 border-gray-200 p-3 text-black ${showMultiSelects ? '' : 'border-dashed'}`}
        >
          {showMultiSelects ? (
            <div className="grow text-center text-gray-500">
              Cancel
            </div>
          ) : (
            <>
              <img
                src={plus}
                alt="Preferences"
                className="mr-2 shrink-0 px-1"
                draggable={false}
              />
              <div className="grow text-start text-gray-500">
                Add criteria
              </div>
              <div className="ml-auto shrink-0 text-gray-500">
                {availableCriteria}
                {' '}
                left
              </div>
            </>
          )}
        </button>
      )}

      {showMultiSelects && (
        <NestedDropdown
          filter={false}
          onClose={onDropdownClose}
          onCreateAttribute={onCreateAttribute}
        />
      )}
      <div className="mt-3 text-sm font-medium text-gray-400">
        Don’t see the targeting options you need?
      </div>
      <div className="text-sm text-gray-400">
        Contact our
        {' '}
        <a
          onClick={handleContactEvent}
          href="mailto:support@invisibly.com?subject=Invisibly%20-%20Custom%20Targeting%20Options%20Inquiry"
          className="text-gray-400 underline"
        >
          support team
        </a>
        {' '}
        for custom targeting options.
      </div>
    </div>
  );
};

export default Criteria;
