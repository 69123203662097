import { useState, useEffect, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {useCreateAdminSurveyMutation} from "../../redux/admin/survey/survey.endpoints";
import { OrderList, OrderListChangeEvent } from 'primereact/orderlist';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { RadioButton } from 'primereact/radiobutton';
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";

interface Question {
  title: string,
  id: string
}
function SurveyCreateScreen() {
  useSetAdminPageTitle('Create survey');
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();

  const options = ['single-select', 'multi-select'];
  const [questionType, setQuestionType] = useState('single-select');
  const [shuffleType, setShuffleType] = useState('none');


  const [title, setTitle] = useState<string>('');
  const [points, setPoints] = useState<string>('');
  const [csv, setCsv] = useState<File>();
  const [submitCsv, { isSuccess, error }] = useCreateAdminSurveyMutation();


  const nav = useNavigate();

  useEffect(() => {
    const data: Question[] = [
      {
        title: 'Question 1',
        id: '1',
      },
      {
        title: 'Question 2',
        id: '2',
      },
      {
        title: 'Question 3',
        id: '3',
      },
      {
        title: 'Question 4',
        id: '4',
      },
    ];

    setQuestions(data);
    setCurrentQuestion(data[0]);
  },[]);

  useEffect(() => {
    isSuccess && console.log('created survey!');
  }, [isSuccess]);

  useEffect(() => {
    error && console.log('survey creation error: ', error);
  }, [error]);

  const itemTemplate = (item: Question) => {
    return (
      <div
        className="align-items-center flex flex-wrap gap-3 p-2"
        data-id={item.id}
      >
        <div className="flex-column flex flex-1 gap-2 xl:mr-8">
          <div className="align-items-center flex gap-2">
            <i className="pi pi-bars text-sm" />
            <span>
              {item.title}
            </span>
            {/* <Fieldset legend={item.title}></Fieldset> */}
            {/* <Panel className="col" header={item.title}></Panel> */}
          </div>
        </div>
      </div>
    );
  };

  function onSelect(e: SyntheticEvent<HTMLDivElement, Event>) {
    console.log('onSelect: '+e);
  }

  function onChange(event: OrderListChangeEvent) {
    setQuestions(event.value);
  }

  function onBlur(event: SyntheticEvent<HTMLElement, FocusEvent>) {
    // console.log(event)
    // console.log('id='+(event.target as any).childNodes[0].getAttribute('data-id')
    // console.log('id='+event.element.childNodes[0].getAttribute('data-id')
    const id = (event.target as any).childNodes[0].getAttribute('data-id');
    const q = questions.filter((q) => {
      if (q.id == id) {
        return q;
      }
    });
    setCurrentQuestion(q[0]);


  }

  function createSurvey(): void {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('points', points);
    formData.append('file', csv || '');
    void submitCsv(formData);
  }

  return (
    <div>
      <div className="justify-content-center flex grid flex-col flex-wrap pt-4">
        <div className="col-3">
          <OrderList
            dataKey="id"
            value={questions}
            header="Questions"
            onFocus={onBlur}
            onChange={onChange}
            itemTemplate={itemTemplate}
            onSelect={onSelect}
            dragdrop
          />
        </div>
        <div className="col">
          { currentQuestion && (
            <Panel header={currentQuestion?.title}>
              <h4>
                Question
              </h4>
              <span className="p-float-label">
                {/* value={value} onChange={(e) => setValue(e.target.value)} */}
                <InputText id="title" />
                <label htmlFor="title">
                  Title
                </label>
              </span>
              <h4>
                Type
              </h4>
              <SelectButton
                value={questionType}
                onChange={(e) => setQuestionType(e.value)}
                options={options}
              />
              <div className="align-items-center flex">
                <RadioButton
                  inputId="question-type-single"
                  name="question_type"
                  value="single-select"
                  onChange={(e) => setQuestionType(e.value)}
                  checked={questionType === 'single-select'}
                />
                <label
                  htmlFor="ingredient1"
                  className="ml-2"
                >
                  Single Choice
                </label>
              </div>
              <div className="align-items-center flex">
                <RadioButton
                  inputId="ingredient2"
                  name="question_type"
                  value="multi-select"
                  onChange={(e) => setQuestionType(e.value)}
                  checked={questionType === 'multi-select'}
                />
                <label
                  htmlFor="ingredient2"
                  className="ml-2"
                >
                  Multi Choice
                </label>
              </div>
              <h4>
                Shuffle
              </h4>
              <div className="align-items-center flex">
                <RadioButton
                  inputId="shuffle-none"
                  name="shuffle_type"
                  value="none"
                  onChange={(e) => setShuffleType(e.value)}
                  checked={shuffleType === 'single-select'}
                />
                <label
                  htmlFor="shuffle-none"
                  className="ml-2"
                >
                  None
                </label>
              </div>
              <div className="align-items-center flex">
                <RadioButton
                  inputId="shuffle-random"
                  name="shuffle_type"
                  value="random"
                  onChange={(e) => setShuffleType(e.value)}
                  checked={shuffleType === 'multi-select'}
                />
                <label
                  htmlFor="shuffle-random"
                  className="ml-2"
                >
                  Random
                </label>
              </div>

              <div className="align-items-center flex">
                <RadioButton
                  inputId="shuffle-revers"
                  name="shuffle_type"
                  value="reverse"
                  onChange={(e) => setShuffleType(e.value)}
                  checked={shuffleType === 'multi-select'}
                />
                <label
                  htmlFor="shuffle-reverse"
                  className="ml-2"
                >
                  Random
                </label>
              </div>
              <h4>
                Answers
              </h4>
              <span className="p-float-label">
                {/* value={value} onChange={(e) => setValue(e.target.value)} */}
                <InputText id="title" />
                <label htmlFor="title">
                  Title
                </label>
              </span>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
}

export default SurveyCreateScreen;
